<template>
  <div class="home-box1">
    <div class="w">
      <!-- 我们正在进入元宇宙 -->
      <div class="box1T">{{ $t("home.box1T") }}</div>
      <!-- PolySmartChain让它变得更好! -->
      <div class="box1SubT">{{ $t("home.box1ST") }}!</div>
      <a href="javascript:;">
        <!-- 开始使用 -->
        <div class="but" @click="uti.goPath('/dev')">
          {{ $t("home.box1But") }}
        </div>
      </a>
      <div class="text">
        <!-- PSC是由社区驱动的技术，为加密货币PSC和成千上万的去中心化应用程序提供动力。 -->
        {{ $t("home.box1FT") }}
      </div>
    </div>
    <div class="ftout">
      <div class="ft">
        <div class="item">
          <!-- 出块时间 -->
          {{ $t("home.box1Ft1") }}
          <div class="rr">3s</div>
        </div>
        <div class="item">
          <!-- tps -->
          {{ $t("home.box1Ft2") }}
          <div class="rr">2,000+</div>
        </div>
        <div class="item">
          <!-- 价格 -->
          {{ $t("home.box1Ft3") }}
          <div class="rr">{{ store.homePrice }}</div>
        </div>
        <div class="item">
          <!-- Staking 收益率 -->
          {{ $t("home.box1Ft4") }}
          <div class="rr">{{ store.caculate }}%</div>
        </div>
        <div class="item">
          <!--  销毁 -->
          Destroy
          <!-- {{ $t('home.box1Ft5') }} -->
          <div class="rr">{{ store.DestroyNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import abi from "../../assets/abi";
import ercabi from "../../assets/erc20Abi";
import Decimal from "decimal.js";

export default {
  async mounted() {
    // 生成web3
    this.web3 = new this.$web3(
      "https://seed0.PolySmartChain.com" || "https://seed1.PolySmartChain.com"
    );
    await this.getCakeRewardPerBlock();
    await this.getTotalValue();
    await this.balanceof();
    //计算收益率
    this.calculate();
    this.getPrice();
    this.getWPSCDestroyNumber();
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      web3: "",
      cakeRewardPerBlock: "",
      poolInfo: "",
      totalValue: "",
      balance: "",
      totalSupply: "",
      price: "",
      masterChef: window.wl.masterChef,
      puLp: window.wl.puLp,
      weth: window.wl.weth,
    };
  },
  methods: {
    // 获取cakeRewardPerBlock 和 poolInfo
    async getCakeRewardPerBlock() {
      let myContract = new this.web3.eth.Contract(abi, this.masterChef);
      this.cakeRewardPerBlock = await myContract.methods.eggPerBlock().call();
      let { allocPoint } = await myContract.methods.poolInfo(0).call();
      this.poolInfo = allocPoint;
      // console.log('cakeRewardPerBlock:' + this.cakeRewardPerBlock);
      // console.log('poolInfo:' + this.poolInfo);
    },
    // 获取lptoken数量
    async getTotalValue() {
      let myContract = new this.web3.eth.Contract(ercabi, this.weth);
      this.totalValue = await myContract.methods.balanceOf(this.puLp).call();
      // console.log('totalValue:' + this.totalValue);
    },
    // 获取balance 和 totalSupply
    async balanceof() {
      let myContract = new this.web3.eth.Contract(ercabi, this.puLp);
      this.balance = await myContract.methods.balanceOf(this.masterChef).call();
      // console.log(this.balance);
      this.totalSupply = await myContract.methods.totalSupply().call();
      // console.log(this.totalSupply);
      // console.log('totalValue:' + this.totalValue);
    },
    // 计算收益率
    calculate() {
      /**
       *            cakeRewardPerBlock * 6307200 * (poolInfo / 12000)
       *  apy  =   ——————————————————————————————————————————————————————
       *                  totalValue / ( balance / totalSupply )
       */

      /**
       *            cakeRewardPerBlock * 6307200 * (poolInfo / 12000)
       *  apy  =   ——————————————————————————————————————————————————————
       *                              totalValue
       */

      if (
        !this.cakeRewardPerBlock ||
        !this.totalValue ||
        !this.poolInfo ||
        !this.balance ||
        !this.totalSupply
      ) {
        return;
      }
      let a = new Decimal(6307200);
      let b = new Decimal(12000);
      let cakeRewardPerBlock = new Decimal(this.cakeRewardPerBlock);
      let totalValue = new Decimal(this.totalValue);
      let poolInfo = new Decimal(this.poolInfo);
      // let balance = new Decimal(this.balance);
      // let totalSupply = new Decimal(this.totalSupply);

      let apy = cakeRewardPerBlock
        .mul(a.mul(poolInfo.div(b)))
        .div(totalValue)
        .mul(100)
        .toFixed(2);
      this.store.caculate = apy;
    },
    // 获取psc价格
    async getPrice() {
      let { data } = await this.$http.get(window.wl.pscPriceURL);
      // this.price = data.psc_price.substr(1);
      this.store.homePrice = data.psc_price.substr(1);
      // console.log(this.price);
      localStorage.setItem("homePrice", data.psc_price.substr(1));
    },
    // 获取wpsc销毁量
    async getWPSCDestroyNumber() {
      let myContract = new this.web3.eth.Contract(
        ercabi,
        window.wl.pscDestroyAddr
      );
      let destroy = await myContract.methods
        .balanceOf("0x0000000000000000000000000000000000000000")
        .call();
      this.store.DestroyNumber = this.uti.addThousand(this.uti.addDec(destroy));
    },
  },
};
</script>

<style lang="less" scoped>
.home-box1 {
  // height: 100vh;
  min-height: 1080px;
  background-color: rgb(0, 47, 255);
  background-image: url("../../assets/images/v4/home/box1Bg.png");
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  padding-top: 230px;
  box-sizing: border-box;
  .box1T {
    font-size: 100px;
    font-weight: bold;
    color: #ffffff;
  }
  .box1SubT {
    font-size: 36px;
    font-weight: bold;
    color: #fcfcfc;
    margin-top: 40px;
  }
  .but {
    width: 288px;
    height: 86px;
    line-height: 86px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px rgba(5, 110, 178, 0.5);
    border-radius: 43px;
    font-size: 34px;
    font-weight: 400;
    color: #333333;
    margin: 0 auto;
    margin-top: 80px;
  }
  .text {
    width: 753px;
    height: 66px;
    font-size: 24px;
    font-weight: 500;
    color: #fcfcfc;
    margin: 0 auto;
    margin-top: 80px;
  }
  .ftout {
    height: 122px;
    width: 100%;
    background-image: url("../../assets/images/v4/home/box1Ft.png");
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    bottom: 0;
    background-size: 100% 100%;
    display: flex;
  }
  .ft {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-image: linear-gradient(to right),
      url("../../assets/images/v4/home/box1Ft.png");
    bottom: 0;
    width: 100%;
    min-width: 1400px;
    max-width: 1920px;
    height: 122px;
    // border: 2px solid rgba(255, 255, 255, 0.56);
    // box-shadow: 0px 2px 0px 0px rgba(209, 209, 209, 0.58);
    // background-color: #d1d1d15e;

    background-size: 100% 100%;
    display: flex;
    .item {
      flex: 1;
      border-right: 2px solid #f1f1f1;
      height: 87px;
      margin-top: 18px;
      padding: 0 85px;
      font-size: 22px;
      color: #fff;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rr {
        font-size: 30px;
        font-weight: bold;
      }
    }
    .item:nth-last-child(1) {
      border: none;
    }
  }
}
</style>
