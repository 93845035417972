<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title1">
        <div class="gang"></div>
        <!-- PolySmartChain 的开始 -->
        <div class="t">{{ $t("home.box3T") }}</div>
        <div class="gang"></div>
      </div>
      <div class="box">
        <div
          class="item"
          v-for="(item, index) in $t('home.box3ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/home/box3${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="p pfont">{{ item.text }}</div>
          <div class="more">
            <a
              href="javascript:;"
              @click="uti.goPath(store.link.home.box3List[index])"
            >
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use></svg></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  height: 1181px;
  margin-top: -283px;
  background-image: url("../../assets/images/v4/home/box3Bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 204px;
  box-sizing: border-box;

  .box {
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
    width: 100%;
    .item {
      width: 330px;
      height: 472px;
      background: #ffffff;
      border-radius: 10px;
      transition: 0.5s;
      text-align: center;
      position: relative;
      z-index: 20;
      padding: 0 10px;
      box-sizing: border-box;
      .img {
        text-align: center;
        margin-top: 61px;
        img {
          width: 75px;
          height: 75px;
        }
      }
      .t {
        margin-top: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
      .link {
        width: 52px;
        height: 2px;
        background: #999;
        margin: 17px auto;
      }
      .p {
        display: block;
        color: #999999;
        height:30px;
      }
      .more {
        position: absolute;
        // width: 100%;
        bottom: 56px;
        left: 50%;
        transform: translateX(-50%);
        color: #0168ec;
        a {
          color: #0168ec;
        }
      }
    }
    .item::after {
      position: absolute;
      content: "";
      z-index: -1;
      left: 0;
      top: 0;
      height: 472px;
      width: 100%;
      // background-color: pink;
      // z-index: 19;
      transition: 0.5s;
    }
    .item:hover {
      margin-top: -65px;
    }
    .item:hover::after {
      margin-top: 65px;
    }
  }
}
</style>
