<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <div class="lan">
            <!-- PolySmartChain -->
            {{ $t('home.box4Tl') }}
          </div>
          <!-- 质押 -->
          {{ $t('home.box4Tr') }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="box">
        <div class="box1">
          <div class="t1">
            <!-- 质押PSC -->
            {{ $t('home.box4Box1T1') }}
          </div>
          <div class="t2">
            <!-- Farm上的质押 -->
            {{ $t('home.box4Box1T2') }}
          </div>
          <div class="p pfont">
            <!-- 通过质押您的 PSC 从而获得奖励。选择您的质押偏好，点击几下即可开始赚钱。 -->
            {{ $t('home.box4Box1ST') }}
          </div>

          <a href="javascript:;">
            <div class="but" @click="uti.goPath(store.link.home.box4But)">
              <!-- 立即质押 -->
              {{ $t('home.box4Box1But') }}
            </div>
          </a>
          <div class="ft">
            <div class="ft1">
              <div class="ftT">
                <!-- 赚取高达 365% 的年利率 -->
                {{ $t('home.box4Box1FtLT') }}
              </div>
              <div class="ftP pfont">
                <!-- 从您的资本中赚取大约 1% 的 APY，对于一年的质押承诺，最高可赚取 365%。 -->
                {{ $t('home.box4Box1FtLP') }}
              </div>
            </div>
            <div class="ft2">
              <div class="ftT">
                <!-- 选择您的奖励 -->
                {{ $t('home.box4Box1FtRT') }}
              </div>
              <div class="ftP pfont">
                <!-- 您可以选择将您的委托锁定多长时间：从无锁定到长达一年的锁定以增加质押奖励。 -->
                {{ $t('home.box4Box1FtRP') }}
              </div>
            </div>
          </div>
        </div>
        <div class="box2">
          <div class="title">
            <!-- 估计你的回报 -->
            {{ $t('home.box4Box2T') }}
          </div>
          <div class="flex flexT">
            <div class="ll">
              <div class="t">
                <div class="num">
                  <!-- PSC -->
                  {{ $t('home.box4Box2Acc') }}
                  : {{ val1 }}
                </div>
              </div>
            </div>
            <div class="rr">
              <!-- 请输入数量 -->
              <input
                type="text"
                v-model="val1"
                class="inp"
                :placeholder="$t('home.box4Box2Tip1')"
              />
            </div>
          </div>
          <el-slider v-model="val1" :max="2000000"></el-slider>
          <div class="flex">
            <div class="ll">0</div>
            <div class="rr">2000000</div>
          </div>
          <div class="flex flexT">
            <div class="ll">
              <div class="t">
                <!-- 质押 -->
                {{ $t('home.box4Box2Pledge') }}
                <div class="day">
                  {{ val2 || 0 }}
                  <!-- 天 -->
                  {{ $t('home.box4Box2PledgeDay') }}
                </div>
              </div>
            </div>
            <div class="rr">
              <!-- 请输入天数 -->
              <input
                type="text"
                v-model="val2"
                class="inp"
                :placeholder="$t('home.box4Box2Tip2')"
              />
            </div>
          </div>
          <el-slider v-model="val2" :max="365"></el-slider>
          <div class="flex">
            <div class="ll"></div>
            <div class="rr">
              365
              <!-- 天 -->
              {{ $t('home.box4Box2PledgeDay') }}
            </div>
          </div>
          <div class="flex flexFt">
            <div class="ll">
              <div class="t">
                <!-- 您的预估回报 -->
                {{ $t('home.box4Box2FtL') }}
              </div>
              <div class="lan">{{ jisuan(val1, val2, store.caculate) }}PSC</div>
            </div>
            <div class="rr">
              <div class="t">
                <!-- 当前年利率 -->
                {{ $t('home.box4Box2FtR') }}
              </div>
              <div class="lan">{{ store.caculate }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      val1: 1000000,
      val2: 100,
      z: 100,
    };
  },
  methods: {
    jisuan(x, y, z) {
      // 回报 = 金额 * (天数/365) * (年利率/100)

      return x * (y / 365) * (z / 100);
    },
  },
};
</script>

<style lang="less" scoped>
.home-box {
  height: 927px;
  // background-color: #f4faff;
  .box {
    display: flex;
    margin-top: 137px;
    .box1 {
      flex: 1;
      .t1 {
        font-size: 30px;
        font-weight: bold;
        color: #0162eb;
      }
      .t2 {
        font-size: 60px;
        font-weight: 500;
        color: #333333;
        margin-top: 16px;
      }
      .p {
        font-size: 18px;
        font-weight: 500;
        color: #999999;
        margin-top: 35px;
      }
      .but {
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        padding: 0 50px;
        display: inline-block;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 61px;
      }
      .ft {
        display: flex;
        margin-top: 137px;
        .ft1,
        .ft2 {
          padding-right: 50px;
          .ftT {
            font-size: 24px;
            color: #333;
          }
          .ftP {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
          }
        }
      }
    }
    .box2 {
      width: 646px;
      height: 679px;
      background: #f0f7ff;
      box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
      border-radius: 10px;
      position: relative;
      padding: 43px;
      box-sizing: border-box;
      .title {
        padding: 0 34px;
        line-height: 44px;
        font-size: 18px;
        height: 44px;
        display: inline-block;
        color: #fff;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        border-radius: 22px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #999999;
        .rr {
          text-align: right;
        }
        .t {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
        .num {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          display: inline-block;
        }
        .day {
          font-size: 24px;
          font-weight: bold;
          color: #0168ec;
          display: inline-block;
        }
        .inp {
          width: 186px;
          height: 44px;
          background: rgba(252, 252, 252, 0.91);
          box-shadow: 0px 2px 8px 0px rgba(202, 221, 243, 0.5);
          border-radius: 22px;
          border: none;
          padding: 0 26px;
          outline: none;
        }
        .lan {
          margin-top: 22px;
          font-size: 24px;
          font-weight: bold;
          color: #0168ec;
        }
      }
      .flexT {
        height: 44px;
        margin-top: 50px;
      }
      .flexFt {
        position: absolute;
        bottom: 43px;
        width: calc(100% - 86px);
      }
      .el-slider {
        margin: 50px 0 0;
      }
    }
  }
}
</style>
