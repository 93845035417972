<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t hh">
          <div class="lan hh">
            <!-- PolySmartChain -->
            {{ $t("home.box2Tl") }}
          </div>
          <!-- 为元宇宙构建 -->
         
          {{ $t("home.box2Tr") }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="box">
        <!-- PSC 构建原理 -->
        <div class="t tchange" >
          {{ $t("home.box2ST") }}
        </div>
        <div class="p pfont">
          <!--  -->
          <!-- PolySmartChain是一个快速、高吞吐量的开源智能合约平台，它结合了突破性的技术，为去中心化的应用程序、系统和社会提供无与伦比的安全性和可持续性。 -->
          {{ $t("home.box2P") }}
        </div>
        <a href="javascript:;">
          <div class="but" @click="uti.goPath('/dev')">
            <!-- 立即探索 -->
            {{ $t("home.box2But") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.hh{
  display:block; 
  text-align:center;
}

.home-box {
  height: 1213.1px;
  // background: #f4faff, url("../../assets/images/v4/home/box2Box.png");
  background-color: #f4faff;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  padding-top: 89px;
  box-sizing: border-box;
  background-repeat: no-repeat;

  .wl-title2 {
    margin-bottom: 79px;
  }
  .box {
    width: 1400px;
    height: 618px;
    background-image: url("../../assets/images/v4/home/box2Box.png");
    border-radius: 20px;
    position: relative;
    // display: none;
    .t {
      // width: 381px;
      height: 56px;
      font-size: 48px;
      font-weight: 500;
      position: absolute;
      left: 103px;
      top: 91px;
      color: #333333;
      
   
    }
    
    .p {
      
      width: 581px;
      height: 90px;
      line-height: 30px;
      font-size: 20px;
      font-weight: 500;
      color: #999999;
      position: absolute;
      left: 103px;
      top: 200px;
    }
    .but {
      text-align: center;
      line-height: 54px;
      width: 280px;
      color: #fff;
      height: 54px;
      background: linear-gradient(-90deg, #10c1fc, #3f65f9);
      box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
      border-radius: 27px;

      position: absolute;
      left: 103px;
      top: 430px;
    }
  }
}

</style>
