<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <!-- 合作伙伴与生态 -->
          {{ $t('home.box7T') }}
        </div>
        <div class="gang"></div>
      </div>
    </div>
    <!-- <div class="box"></div> -->
    <div class="box2">
      <div class="ul">
        <div class="li" v-for="(path, index) in sponsor" :key="index">
          <img :src="path" />
        </div>
      </div>
      <div class="ul">
        <div class="li" v-for="(path, index) in sponsor" :key="index">
          <img :src="path" />
        </div>
      </div>
      <div class="ul">
        <div class="li" v-for="(path, index) in sponsor" :key="index">
          <img :src="path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';

export default {
  mounted() {
    // this.setTm();
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      sponsor: [
        require('../../assets/images/v4/support/BNB Chain.png'),
        require('../../assets/images/v4/support/BUSD.png'),
        require('../../assets/images/v4/support/DAI.png'),
        require('../../assets/images/v4/support/Ethereum.png'),
        require('../../assets/images/v4/support/PolyDao.png'),
        require('../../assets/images/v4/support/Polygon.png'),
        require('../../assets/images/v4/support/Polyjetclub.png'),
        require('../../assets/images/v4/support/Social.png'),
        require('../../assets/images/v4/support/Proposal.png'),
        require('../../assets/images/v4/support/SwanSwap.png'),
        require('../../assets/images/v4/support/Tron.png'),
        require('../../assets/images/v4/support/USDC.png'),
        require('../../assets/images/v4/support/USDT.png'),
        require('../../assets/images/v4/support/Wisdomchain.png'),
      ],
      ul: [
        {
          a: '1',
        },
        {
          a: '2',
        },
        {
          a: '3',
        },
        {
          a: '4',
        },
      ],
      list: {},

      tm: '',
    };
  },
  methods: {
    // setTm() {
    //   let n = 5;
    //   this.tm = setInterval(() => {
    //     this.ul.shift();
    //     this.ul.push({
    //       a: n++,
    //     });
    //   }, 5000);
    // },
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 100vh;
  margin-top: -150px;
  // background-color: rgb(0, 47, 255);
  padding-bottom: 150px;
  background-image: url('../../assets/images/v4/home/box7LB.png');
  background-repeat: no-repeat;
  background-position: left -130%;
  .box {
    // background-color: pink;
    margin-top: 56px;
    height: 366px;
    background-image: url('../../assets/images/v4/home/box7.png');
    animation: toLeft 18s infinite linear;
  }
  .box2 {
    width: 100%;
    overflow: hidden;
    .ul {
      // display: flex;
      width: 100%;
      padding: 20px 0;
      // height: 90px;
      // flex-wrap: wrap;
      white-space: nowrap;
      // overflow: auto;
      animation: toLeft2 60s infinite linear;
      .li {
        display: inline-block;
        // flex: 0;
        width: 225px;
        height: 98px;
        background-color: white;
        margin-left: 15px;
        border-radius: 10px;
        transition: 0.3s;
        img {
          width: 100%;
        }
      }
      .li:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
      }
    }
    .ul:nth-child(2) {
      animation-name: toLeft3;
    }
  }
}

@keyframes toLeft {
  100% {
    background-position: -1920px 0;
  }
}
@keyframes toLeft2 {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -50%;
  }
  100% {
    margin-left: 0%;
  }
}
@keyframes toLeft3 {
  0% {
    margin-left: -50%;
  }
  50% {
    margin-left: 0;
  }
  100% {
    margin-left: -50%;
  }
}
</style>
