<template>
  <div class="home-box">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <!-- PSC Ecosystem -->
          {{ $t('home.box6T') }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="box">
        <div
          class="wl-item2"
          v-for="(item, index) in $t('home.box6ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/home/box6${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <!-- <div class="android">{{ item.subTitle }}</div> -->
          <div class="p pfont">
            {{ item.text }}
          </div>
          <div class="more">
            <a
              href="javascript:;"
              @click="uti.goPath(store.link.home.box6List[index])"
            >
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use>
              </svg>
            </a>
          </div>
        </div>
        <div class="zw"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 100vh;
  // height: 1495px;

  // background-color: rgb(0, 47, 255);
  background-color: #fff;
  background-image: url('../../assets/images/v4/home/box2Bg.png'),
    url('../../assets/images/v4/home/box6Ft.png');
  background-repeat: no-repeat;
  background-position: 0 60%, right bottom;
  padding: 140px;
  padding-bottom: 250px;
  box-sizing: border-box;
  .box {
    margin-top: 100px;
    // background-color: pink;
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wl-item2 {
      transition: 0.5s;
      // background: none;
      // background: linear-gradient(-45deg, #fff, #fff);
      .android {
        font-size: 14px;
        margin-top: 5px;
        font-weight: 400;
        color: #cccccc;
      }
      .link {
        margin-bottom: 0px;
      }
      .p {
        margin-top: 15px;
        // height:25px;
        text-overflow: ellipsis;
      }
    }
    .wl-item2:hover {
      background: #015eea;
      // background: linear-gradient(-45deg, #015eea, #00c0fa);
      color: #ffffff;
      .t {
        color: #ffffff;
      }
      .link {
        background-color: #fff;
      }
      .android {
        color: #ffffff;
      }
      .p {
        color: #ffffff;
      }
      .more {
        color: #ffffff;
        a {
          color: #ffffff;
        }
      }
    }
    .zw {
      // flex: 1;
      width: 446px;
    }
  }
}
</style>
